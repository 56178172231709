.MenuPartWrapper {
  padding: 0.25rem;
  width: 100%;
  background-color: #00264a;
  border-radius: 5px;
}

.MenuFlexiblePart {
  align-self: flex-start;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-basis: 100%;
}

.MenuChildrenWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 0.5rem;
  overflow: hidden;
  gap: 0.75rem;
}

.PoisList {
  padding-left: 1.5rem;
  overflow: auto;
  flex-grow: 1;
}

.FilterOptionsWrapper {
  overflow: hidden;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.CategoriesOptionsWrapper {
  list-style: none;
  padding-left: 0;
  overflow: auto;
  flex-grow: 1;
}
