.PoiButton {
  background: none;
  border: none;
  padding: 0;
  text-align: left;
  cursor: pointer;
}

.PoiButton:hover {
  opacity: 0.6;
}
